@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

body {
    background-color: #ECF0F5;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
}

* {
    scrollbar-color: #c0c0c0 #f4f4f4;
}

* {
    scrollbar-color: #c0c0c0 transparent;
}